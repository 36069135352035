import cx from 'classnames';
import { Link, PageProps } from 'gatsby';
import Img from 'gatsby-image';
import React, { useEffect, useState } from 'react';
import { SizeMe } from 'react-sizeme';
import StackGrid from 'react-stack-grid';
import blogListStyles from '../components/BlogList/bloglist.module.scss';
import PageFooterStudio from '../components/pageFooter/pageFooterStudio';
import SEO from '../components/seo';
import { usePageContext } from '../contexts/pageContext';
import { useMdStudioData } from '../static-queries/useMdStudioData';
import styles from './studio.module.scss';

const StudioPage: React.FC<PageProps<null>> = ({data}) => {
  const randNum = () => Math.floor(Math.random() * 256);
  const [color, setColor] = useState(
    `rgb(${randNum()},${randNum()},${randNum()})`
  );

  const [isHover, setIsHover] = useState(false);

  const changeColor = () => {
    setColor(`rgb(${randNum()},${randNum()},${randNum()})`);
  };

  const {setIsSideBarShown, setPageType} = usePageContext();

  useEffect(() => {
    const colorTimer = setInterval(changeColor, 125);
    setIsSideBarShown(false);
    setPageType('studio');
    return () => {
      clearInterval(colorTimer);
    };
  }, []);
  // const categories = data.categories.group
  function BuildImage(image: any) {
    const imageEl = image.hero_image;
    return !!imageEl ? (
      <Img
        className={cx(blogListStyles.imageContainer)}
        fluid={imageEl.childImageSharp.fluid}
        alt={image.title}
      />
    ) : null;
  }
  const renderContent = (content: string) => {
    // This regex captures the content between <!-- cv-start --> and <!-- cv-end --> tags
    const regex = /<!-- cv-start -->([\s\S]*?)<!-- cv-end -->/gm;
    const matches = regex.exec(content);

    // Return only the content between the cv tags if it exists, otherwise an empty string
    return matches ? matches[1] : '';
  };

  const studioData = useMdStudioData();

  return (
    <div className={'min-h-screen relative'}>
      <SEO title="Studio | Gordo" />
      <div className="max-w-4xl mx-auto min-h-screen">
        <section
          className={cx(
            // 'h-full md:flex md:items-center sm:p-3 block py-6 md:justify-center min-h-screen md:mt-0 overflow-y-scroll z-50 w-full',
            styles.mainContainer
          )}>
          <div className={'md:px-6 px-3 w-full py-32 '}>
            {/* <section className="my-10 text-gray-700 font-writing">
            <h2 className="mb-6 font-studio leading-9">
              Studio
            </h2>
            <h3 className="mb-10 font-studio leading-6 tracking-wider">
              Experience building the whole product stack around Governance,
              digital arts, and digital identity.
            </h3>
            <p className="mb-2 leading-6">
              Possessing a robust creative background as a former architect and
              over 15 years of self-taught technical expertise building and working in start-ups, I code in
              several languages to create engaging projects. With more than 14
              years of experience in tech products, I have built, designed, and
              led products in various areas of the field. I'm used to wear many hats.
            </p>
            

            <hr className="my-10 text-blac leading-6"></hr>
          </section> */}


            {studioData && (
              <>
                <SizeMe>
                  {({size}) => {
                    return (
                      <StackGrid
                        appearDelay={100}
                        columnWidth={
                          size.width >= 960
                            ? '50%'
                            : size.width >= 640
                            ? '50%'
                            : '50%'
                        }

                        gutterWidth={size.width <= 640 ? 5 : 10}
                        gutterHeight={size.width <= 640 ? 5 : 10}>
                        {/* 
                        
                        CONTENT
                        
                        */}

                        {studioData.allMarkdownRemark.edges.map((edge: any) => (
                          <div
                            className={cx(styles.post, 'font-studio border border-gray-300 hover:border-black transition-all')}
                            key={edge.node.id}>

                            <Link
                              className="cursor-pointer"
                              to={`/studio/${edge.node.fields.slug}`}>
                              {(
                                <div className={blogListStyles.list__hero}>
                                  {BuildImage(edge.node.frontmatter)}
                                </div>
                              )}
                              <div className="p-2" key={edge.node.id}>
                                {/* <p className="font-studio text-xs text-gray-600 mb-1 tracking-wide">
                                  {edge.node.frontmatter.dateStart} -{' '}
                                  {edge.node.frontmatter.dateEnd
                                    ? edge.node.frontmatter.dateEnd
                                    : 'Ongoing'}
                                </p> */}
                                <div className="flex gap-2 justify-between">
                                  <h3 className="font-studio text-sm md:text-xl text-gray-800 tracking-wide pr-5 mb-0">
                                    {edge.node.frontmatter.title}
                                  </h3>
                                  <Link
                                    className={cx(
                                      'transition-all', styles.dot
                                    )}
                                    to={`/${edge.node.fields.type}/${edge.node.fields.slug}`}>
                                    <div className='h-2 w-2 rounded-full bg-black'></div>
                                  </Link>
                                </div>
                                {/* <p>{edge.node.excerpt}</p> */}
                                {/* <div
                                  className={cx(
                                    blogTemplateStyles.blog__body,
                                    'md:pr-10 md:pt-6 pt-4 pb-14 w-full'
                                  )}
                                  dangerouslySetInnerHTML={{
                                    __html: renderContent(edge.node.html),
                                  }}
                                /> */}
                              </div>
                            </Link>
                          </div>
                        ))}
                      </StackGrid>
                    );
                  }}
                </SizeMe>
              </>
            )}
          </div>
        </section>
      </div>
      <PageFooterStudio />
    </div>
  );
};

export default StudioPage;
