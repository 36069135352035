import { graphql, useStaticQuery } from 'gatsby';

export function useMdStudioData() {
    const data = useStaticQuery<GatsbyTypes.getStudioDataQuery>(graphql`
        query getStudioData {
            allMarkdownRemark(
                sort: { fields: [frontmatter___date], order: DESC },
                filter: { fields: { place: { in: ["studio"] } } }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            date(formatString: "MMMM Do, YYYY")
                            hero_image {
                                childImageSharp {
                                    fluid(maxWidth: 800) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        fields {
                            slug
                            type
                        }
                        excerpt(pruneLength: 200)
                    }
                }
            }
        }
    `);
    return data;
}
