import React, { useEffect } from 'react';

type IPageFooterProps = {};

const PageFooterStudio: React.FC<IPageFooterProps> = (props) => {
  const {} = props;

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <hr className="mb-10" />
      <footer
        className={
          'flex flex-col md:flex-row w-full pr-10 pb-5 gap-2 md:gap-4 items-center justify-center'
        }>
        <p className={'font-studio tracking-wide text-xs text-gray-500'}>
          © Gordo Studio
        </p>
        <a href="mailto:hello@gordo.design">
          <p className={'font-studio tracking-wide text-xs text-gray-500'}>
            📬 contact@gordo.design
          </p>
        </a>
        <a href="/base">
          <p className={'font-studio tracking-wide text-xs text-gray-500'}>
            Labs
          </p>
        </a>
      </footer>
    </React.Fragment>
  );
};

export default PageFooterStudio;
